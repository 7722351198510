// MODAL
export const CHANGE_MODAL = 'CHANGE_MODAL'

// LOADER
export const HANDLE_START_LOADER = 'HANDLE_START_LOADER'
export const HANDLE_COMPLETE_LOADER = 'HANDLE_COMPLETE_LOADER'

// GARMENT
export const FETCH_CURRENT_GARMENT = 'FETCH_CURRENT_GARMENT'
export const REMOVE_CURRENT_GARMENT = 'REMOVE_CURRENT_GARMENT'
export const SET_QUERY_GARMENT_TYPE = 'SET_QUERY_GARMENT_TYPE'
export const FETCH_ALL_GARMENTS = 'FETCH_ALL_GARMENTS'
export const FETCH_TYPE_GARMENT = 'FETCH_TYPE_GARMENT'
export const FETCH_GARMENT_DETAIL = 'FETCH_GARMENT_DETAIL'
export const SET_CART_ADDED = 'SET_CART_ADDED'

// MODEL
export const FETCH_ALL_MODELS = 'FETCH_ALL_MODELS'

// LOOK
export const INC_LOOK_LOADING = 'INC_LOOK_LOADING'
export const SET_QUERY_LOOK_ID = 'SET_QUERY_LOOK_ID'
export const FETCH_CURRENT_LOOK = 'FETCH_CURRENT_LOOK'
export const SET_LOOK_INDEX = 'SET_LOOK_INDEX'
export const SET_LOOK_TIMESTAMP = 'SET_LOOK_TIMESTAMP'
export const FETCH_RECOMMENDATIONS = 'FETCH_RECOMMENDATIONS'
export const SET_LOOK_REQUEST = 'SET_LOOK_REQUEST'

// PROFILE
export const FETCH_COMPANY_PROFILE = 'FETCH_COMPANY_PROFILE'
export const SET_PARENT_HEIGHT = 'SET_PARENT_HEIGHT'
export const SET_HEADER_HIDDEN = 'SET_HEADER_HIDDEN'

// FAVORITES
export const HANDLE_ADD_TO_FAVORITES_OUTFIT = 'HANDLE_ADD_TO_FAVORITES_OUTFIT'
export const HANDLE_REMOVE_TO_FAVORITES_OUTFIT = 'HANDLE_REMOVE_TO_FAVORITES_OUTFIT'
export const HANDLE_ADD_TO_FAVORITES_ITEMS = 'HANDLE_ADD_TO_FAVORITES_ITEMS'
export const HANDLE_REMOVE_TO_FAVORITES_ITEMS = 'HANDLE_REMOVE_TO_FAVORITES_ITEMS'
export const SWITCH_FAVORITES_CONTENT = 'SWITCH_FAVORITES_CONTENT'
export const CHANGE_STORAGE_BASE_KEY = 'CHANGE_STORAGE_BASE_KEY'

// FILTERS
export const HANDLE_FILTERS_MODEL = 'HANDLE_FILTERS_MODEL'
export const HANDLE_SHOW_FILTERS = 'HANDLE_SHOW_FILTERS'

// SIDEBAR
export const HANDLE_SIDEBAR = 'HANDLE_SIDEBAR'

// SWIPE
export const FETCH_ALL_TOP_GARMENTS = 'FETCH_ALL_TOP_GARMENTS'
export const FETCH_ALL_BOTTOM_GARMENTS = 'FETCH_ALL_BOTTOM_GARMENTS'
export const FETCH_ALL_DRESS_GARMENTS = 'FETCH_ALL_DRESS_GARMENTS'
export const FETCH_ALL_OUTERWEAR_GARMENTS = 'FETCH_ALL_OUTERWEAR_GARMENTS'

// ERROR
export const SET_ERROR = 'SET_ERROR'
