import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import { ReactFitty } from 'react-fitty'

import { SetLookIndexAction } from '../../store/actions/look'
import { AddFavoritesOutfitAction, RemoveFavoritesOutfitAction } from 'src/store/actions/favorites'
import { trackEvent } from 'src/utils/tracking'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Loader from 'src/components/Loader'
import CardModel from 'src/components/card/CardModel'
import useCustomHistory from 'src/utils/custom-history-hook'
import LookSwitches from 'src/components/LookSwitches'

interface LookContainerProps {
    swipeStyle?: boolean
    scrollToRef?: any
    contentMaxWidth?: number
    hasEnoughWidth?: boolean
}

const LookContainer: React.FunctionComponent<LookContainerProps> = (props) => {
    const customHistory = useCustomHistory()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const look = useSelector((state: State.Root) => state.look?.request)
    const lookLoading = useSelector((state: State.Root) => state.look?.loading)
    const lookIndex = useSelector((state: State.Root) => state.look?.index)
    const favorites = useSelector((state: State.Root) => state.favorites).outfits
    const company = useSelector((state: State.Root) => state.profile?.company)
    const { swipeStyle, scrollToRef, contentMaxWidth, hasEnoughWidth } = props

    const maxWidthStyle = contentMaxWidth && { maxWidth: contentMaxWidth }

    // Check if we need to scroll to the Details
    useEffect(() => {
        const scrollElement = document.getElementById('layoutScrollableContent')
        if (history.state?.state?.toDetail === true) {
            // Need this to clear the history state without rerendering the page. Otherwise toDetail stays true
            window.history.replaceState({}, document.title)

            const localContainerElem = document.getElementById('lookContainerId')
            // We add 40 because of the padding above the lookContainer and the padding on top of the cart
            return scrollElement.scrollTo({
                top: localContainerElem.clientHeight + 40,
                behavior: 'smooth',
            })
        } else {
            return scrollElement.scrollTo({ top: 1 })
        }
    }, [])

    const lookImgRatio = 100 / (company?.look_image_ratio || 0.66)

    if (!look) {
        return (
            <Row className='look look--container' style={{ height: '100%' }}>
                <Loader />
            </Row>
        )
    }

    const addOnFavorites = (current: Models.Look) => {
        dispatch(AddFavoritesOutfitAction([...favorites, current] as any))
    }

    const removeOnFavorites = (current: Models.Look) => {
        const dataList: any = [...favorites]
        const currentIndex = dataList.findIndex((item) => item.look_id === current.look_id)
        if (currentIndex >= 0) dataList.splice(currentIndex, 1)
        dispatch(RemoveFavoritesOutfitAction(dataList))
    }

    const handleChangeModelClick = () => {
        trackEvent('Choose model Clicked', look, 'Outfit')
        customHistory.push('/model')
    }

    const handleBackClick = (index: number, e?: React.MouseEvent) => {
        e?.stopPropagation()
        trackEvent(
            'Back Changed',
            [look, { back_value: index === 1 ? 'on' : 'off', back_type: 'toggle' }],
            'Outfit'
        )
        dispatch(SetLookIndexAction(index))
    }

    const handleSeeLookDetailClick = () => {
        trackEvent('Outfit Detailed', look, 'Outfit')
        if (scrollToRef?.current) {
            // If we are on the mobile we use the window scroll and offset it
            if (window.innerWidth < 768) {
                const localContainerElem = document.getElementById('lookContainerId')
                const scrollElement = document.getElementById('layoutScrollableContent')
                // We add 40 because of the padding above the lookContainer and the padding on top of the cart
                return scrollElement.scrollTo({
                    top: localContainerElem.clientHeight + 40,
                    behavior: 'smooth',
                })
            }

            return scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    return (
        <Row
            id='lookContainerId'
            className={'look look--container' + (swipeStyle ? ' look--container-swipe' : '')}
        >
            <Row
                className={'look--content ' + (swipeStyle ? 'look--swipe-content' : '')}
                style={maxWidthStyle}
            >
                <div className='look--model-button-container'>
                    <div
                        onClick={handleChangeModelClick}
                        className={`button--underlined button--change-model ${
                            hasEnoughWidth ? 'button--change-model-left' : ''
                        }`}
                    >
                        {t('look.change_model')}
                    </div>
                </div>
                {hasEnoughWidth && company.enable_switch_row !== false && (
                    <div className='look--switch-right-container'>
                        <div className='look--switch--row'>
                            <LookSwitches vertical />
                        </div>
                    </div>
                )}

                <Col span={24} id='lookCardContainerId'>
                    <CardModel
                        look={look}
                        lookLoading={lookLoading !== 0}
                        imageUrls={look.image_urls}
                        index={lookIndex}
                        ratio={lookImgRatio}
                        favorites={favorites}
                        addOnFavorites={addOnFavorites}
                        removeFromFavorites={removeOnFavorites}
                        handleBackClick={handleBackClick}
                    />
                    <div className='look--description'>
                        <ReactFitty minSize={9} maxSize={14} wrapText={true}>
                            {look.model?.model_description ||
                                t('model.description', {
                                    modelName: look.model.model_name,
                                    modelHeight: `${Math.floor(
                                        parseInt(look.model.model_height) / 100
                                    )}m${parseInt(look.model.model_height) % 100}`,
                                    modelSize: look.model.model_garment_size,
                                })}
                        </ReactFitty>
                    </div>
                    {company.enable_switch_row !== false && !hasEnoughWidth && (
                        <div className='look--head look--switch--row'>
                            <LookSwitches />
                        </div>
                    )}
                    <Button
                        className={
                            'button look--button' + (swipeStyle ? ' look--button-swipe' : '')
                        }
                        type='primary'
                        onClick={handleSeeLookDetailClick}
                    >
                        {t('look.see_look_detail')}
                    </Button>
                </Col>
            </Row>
        </Row>
    )
}

export default LookContainer
