import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 16,
            color: 'white',
        }}
        spin
    />
)

// Custom Spinner used because it doesn't overflow and keep it's size
export default function CustomSpinner() {
    return <Spin indicator={antIcon} />
}
