import {
    INC_LOOK_LOADING,
    SET_QUERY_LOOK_ID,
    FETCH_CURRENT_LOOK,
    SET_LOOK_INDEX,
    FETCH_RECOMMENDATIONS,
    SET_LOOK_REQUEST,
} from '../actions'

export const initialState: State.Look = {
    queryLookId: null,
    loading: 0,
    current: null,
    index: 0,
    outerwear: null,
    recommendations: null,
    request: null,
}

const LookReducers = (state: State.Look = initialState, action: Types.Action): State.Look => {
    switch (action.type) {
        case INC_LOOK_LOADING:
            return {
                ...state,
                loading: state.loading + action.payload,
            }
        case SET_QUERY_LOOK_ID:
            return {
                ...state,
                queryLookId: action.payload,
            }
        case FETCH_CURRENT_LOOK:
            return {
                ...state,
                queryLookId: action.payload.look_id,
                current: action.payload,
                outerwear: action.payload.outerwear || state.outerwear,
            }
        case SET_LOOK_REQUEST:
            return {
                ...state,
                request: action.payload,
            }
        case FETCH_RECOMMENDATIONS:
            return {
                ...state,
                recommendations: action.payload,
            }
        case SET_LOOK_INDEX:
            return {
                ...state,
                index: action.payload,
            }
        default:
            return state
    }
}

export default LookReducers
