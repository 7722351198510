import React, { useState, useEffect, useRef } from 'react'
import LazyLoad from 'react-lazyload'
import Spin from 'antd/lib/spin'

interface ImageSmoothProps {
    src: string
    ratio: number
    cover?: boolean
    lazyload?: boolean
    transition?: boolean
    loader?: boolean
    loading?: boolean
    className?: string
    overflow?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const ImageSmooth: React.FunctionComponent<ImageSmoothProps> = (props) => {
    const {
        src,
        ratio,
        cover = false,
        lazyload = true,
        transition = true,
        loader = false,
        loading = false,
        className = '',
        overflow = true,
        onClick,
    } = props
    const [currentSrc, setCurrentSrc] = useState(null)
    const [currentLoaded, setCurrentLoaded] = useState(false)
    const [oldSrc, setOldSrc] = useState(null)
    const [oldOpacity, setOldOpacity] = useState(0)
    const startTimeout: any = useRef()
    const transitionTimeout: any = useRef()

    const transitionTime = 500

    const handleOnLoad = (loadedSrc, force = false) => {
        if (loadedSrc === currentSrc || force) {
            setCurrentSrc(loadedSrc)
            setCurrentLoaded(true)
            startTimeout.current = setTimeout(() => {
                setOldOpacity(0)
                transitionTimeout.current = setTimeout(() => setOldSrc(null), transitionTime)
            }, 20)
        }
    }

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (onClick) {
            onClick(e)
        }
    }

    useEffect(() => {
        const customHandleOnLoad = handleOnLoad.bind(this, src)

        clearTimeout(startTimeout.current)
        clearTimeout(transitionTimeout.current)
        ;(window as any).usePreloadImagesData = {}
        const img = new Image()
        if (src !== undefined && src !== null) {
            if (transition) {
                img.src = src as string
                ;(window as any).usePreloadImagesData[src] = img
                if (!img.complete) {
                    img.addEventListener('load', customHandleOnLoad)
                    img.addEventListener('error', customHandleOnLoad)
                }

                if (currentSrc !== null) {
                    setOldSrc(currentSrc)
                    setOldOpacity(0.99)
                }
                setCurrentSrc(src)
                setCurrentLoaded(false)

                if (img.complete) {
                    handleOnLoad(src, true)
                }
            } else {
                setCurrentSrc(src)
                setCurrentLoaded(true)
            }
        }

        return () => {
            if (src !== undefined && src !== null && transition) {
                clearTimeout(startTimeout.current)
                clearTimeout(transitionTimeout.current)
                img.removeEventListener('load', customHandleOnLoad)
                img.removeEventListener('error', customHandleOnLoad)
            }
        }
        // eslint-disable-next-line
    }, [src])

    const isLoading = loading || src != currentSrc || !currentLoaded

    return (
        <div
            className={`imagesmooth--container ${cover ? `imagesmooth--cover` : ''} ${className}`}
            style={{ paddingBottom: `${ratio}%` }}
            onClick={handleOnClick}
        >
            {currentSrc &&
                (lazyload ? (
                    <LazyLoad height='100%' offset={100} resize={true} overflow={overflow} once>
                        <img className='imagesmooth--current' src={currentSrc} draggable='false' />
                    </LazyLoad>
                ) : (
                    <img className='imagesmooth--current' src={currentSrc} draggable='false' />
                ))}
            {oldSrc && (
                <img
                    className='imagesmooth--old'
                    src={oldSrc}
                    draggable='false'
                    style={Object.assign(
                        {
                            opacity: oldOpacity,
                        },
                        currentLoaded
                            ? {
                                  transition: `opacity ${transitionTime / 1000}s ease-out 0s`,
                              }
                            : {}
                    )}
                />
            )}
            {loader && isLoading && (
                <>
                    <div className='imagesmooth--loader-bg' />
                    <div className='imagesmooth--loader'>
                        <Spin size='large' />
                    </div>
                </>
            )}
        </div>
    )
}

export default ImageSmooth
