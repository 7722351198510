// @ts-nocheck
import React, { useEffect } from 'react'

import CarouselModel from 'src/components/carousel/carouselModel'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { trackPage } from 'src/utils/tracking'
import useCustomSize from 'src/utils/size'

const ModelPage: React.FunctionComponent = () => {
    const size = useCustomSize()

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    return (
        <div
            className='model model--container'
            style={size.getLayoutScrollableContentStyle('height')}
        >
            <CarouselModel />
        </div>
    )
}

export default ModelPage
