import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { LeftOutlined } from '@ant-design/icons'

import Tabs from 'antd/lib/tabs'
const { TabPane } = Tabs

import Layout from 'antd/lib/layout'
import Button from 'antd/lib/button'
import LookContainer from '../look/Look'

import { FetchTypeGarmentAction } from '../../store/actions/garment'

import FiltersModel from '../filters/FiltersModel'
import FilterResponsive from '../filters/FilterResponsive'
import { getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'
import CartPage from 'src/pages/cart'
import { SwitchFavoritesContentAction } from 'src/store/actions/favorites'
import useCustomHistory from 'src/utils/custom-history-hook'
import { calcMaxWidth } from 'src/utils/calc-max-width'
import { getFavoriteIcon, getFilterIcon } from 'src/utils/icon'
import LookTracking from '../look/LookTracking'
import { sendIframeMessage } from 'src/utils/iframe'
import { SetParentHeightAction } from 'src/store/actions/profile'
import useCustomSize from 'src/utils/size'
import PoweredBy from 'src/components/PoweredBy'
import Modal from 'antd/lib/modal/Modal'
import ModalAddedCart from 'src/components/ModalAddedCart'
import { handleModalAction } from 'src/store/actions/modal'
import useShowHideHeader from 'src/utils/showHideHeader'
import { useAppSelector } from 'src/store'
import {
    addFilterForType,
    countActiveFilters,
    getAllGarments,
    getCurrentActiveFiltersForType,
    getCurrentFiltersForType,
    getCurrentPageForType,
    resetAllFilterForType,
} from 'src/store/slices/databaseSlice'
import Loader from 'src/components/Loader'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'
import Badge from 'antd/lib/badge'
import { FetchRecommendationsAction, HandleLookRequest } from 'src/store/actions/look'

const { Header, Content } = Layout

interface LayoutProps {
    children: React.ReactNode
}

const LayoutContainer: React.FunctionComponent = (props: LayoutProps) => {
    const location = useLocation()
    const customHistory = useCustomHistory()
    const size = useCustomSize()
    const dispatch = useDispatch()
    const { t } = useCustomTranslation()
    const showHideHeaderScrollListener = useShowHideHeader()
    const company = useSelector((state: State.Root) => state.profile?.company)
    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const favorites = useSelector((state: State.Root) => state.favorites)
    const lookRatio = useSelector((state: State.Root) => state.profile?.company?.look_image_ratio)
    const parentHeight = useSelector((state: State.Root) => state.profile?.parentHeight)
    const modalVisible = useSelector((state: State.Root) => state.modal.open)
    const showFilters = useAppSelector((state) => state.filters.showFilters)
    const lookRecommendations = useSelector((state: State.Root) => state.look?.recommendations)
    const numberActiveFilters = useAppSelector((state) => countActiveFilters(state))
    const garmentFacets = useAppSelector((state) => getCurrentFiltersForType(state, garmentType))
    const currentPage = useAppSelector((state) => getCurrentPageForType(state, garmentType))
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const currentGarment = useAppSelector((state) => state.look.request[garmentType.toLowerCase()])
    const [getGarmentsTrigger, { data: garmentData, isLoading, error }] = useCustomGetGarments()
    const look = useAppSelector((state) => state.look)

    const cartRef = useRef(null)

    const [contentMaxWidth, setContentMaxWidth] = useState<number>(null)
    const [contentActualWidth, setContentACtualWidth] = useState<number>(null)

    // Local filters used for multiselect
    const [localFilters, setLocalFilters] = useState<{ [key: string]: Models.Facet[] } | null>(null)
    const [lastFacetModified, setLastFacetModified] = useState<string | null>(null)
    const activeFilters = useAppSelector((state) =>
        getCurrentActiveFiltersForType(state, garmentType)
    )

    // Use to know when we highlight a subcategory or not
    const onlyOneSubCategory = useMemo(() => {
        return (
            activeFilters &&
            company.garment_category_facets &&
            activeFilters[company.garment_category_facets[garmentType]]?.length === 1 &&
            Object.keys(activeFilters).length === 1
        )
    }, [activeFilters, garmentType, company.garment_category_facets])

    useEffect(() => {
        const updateIframeHeight = (e) => {
            if (typeof e.data !== 'string') {
                return
            }
            const splitted = e.data.split(':')
            const messageName = splitted[0]
            if (messageName === 'veesual_parentsize') {
                const data = JSON.parse(splitted.slice(1).join(':'))
                if (data.height && Number(data.height) !== Number(parentHeight)) {
                    dispatch(SetParentHeightAction(data.height))
                }
            }
        }
        window.addEventListener('message', updateIframeHeight, false)
        sendIframeMessage('veesual_askparentsize', null)
        return () => {
            window.removeEventListener('message', updateIframeHeight)
        }
        // eslint-disable-next-line
    }, [parentHeight])

    useEffect(() => {
        const elementToListen = [
            document,
            document.getElementById('layoutScrollableContent'),
            document.getElementById('layoutRightContentId'),
            // Attention, ces elements doivent aussi etre present dans showHideHeader.ts
        ]

        // ---- Branchements des listeners ----
        for (const element of elementToListen) {
            if (element) {
                element.addEventListener('scroll', showHideHeaderScrollListener)
            }
        }
        return () => {
            for (const element of elementToListen) {
                if (element) {
                    element.removeEventListener('scroll', showHideHeaderScrollListener)
                }
            }
        }
        // eslint-disable-next-line
    }, [])

    // Updates the contextMaxWidth with the size of the container and listen to resizes
    const contentMargin = 20
    useEffect(() => {
        function handleResize() {
            const content = document.getElementById('layoutRightContentId')
            if (content) {
                const tmpStyle = size.getLayoutScrollableContentStyle('height')
                const newContentMaxWidth = calcMaxWidth(
                    window.innerWidth / 2,
                    window.innerWidth / 4,
                    tmpStyle.height || content.clientHeight,
                    lookRatio,
                    // offset = 30 description + 32 toggles + 56 detail (-5 de marge)
                    81 + (company.enable_switch_row !== false ? 32 : 0),
                    contentMargin,
                    contentMargin
                )
                setContentMaxWidth(newContentMaxWidth)
                setContentACtualWidth(
                    newContentMaxWidth > window.innerWidth / 3 &&
                        newContentMaxWidth <= window.innerWidth / 2
                        ? newContentMaxWidth
                        : null
                )
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [lookRatio, parentHeight])

    // We update the local filters when it's empty and we get new facets
    useEffect(() => {
        if (garmentFacets !== undefined) {
            if (!localFilters || !localFilters[garmentType]) {
                setLocalFilters({ ...localFilters, [garmentType]: garmentFacets })
            }

            // We update the count values to existing localFilters
            if (localFilters && localFilters[garmentType]) {
                // Deepcopy of the localFilters to handle the object
                const cloneLocalFilters = JSON.parse(JSON.stringify(localFilters))
                const activeFiltersKeys = Object.keys(activeFilters)

                // Loop on new facetkeys
                Object.keys(garmentFacets).forEach((facetKey) => {
                    // We don't update the data if we just modified this facet
                    if (
                        !lastFacetModified ||
                        lastFacetModified !== facetKey ||
                        activeFiltersKeys.length === 0 ||
                        (activeFilters[lastFacetModified] === undefined &&
                            lastFacetModified === facetKey)
                    ) {
                        const facetKeyData = [...cloneLocalFilters[garmentType][facetKey].data]
                        facetKeyData.forEach((data) => {
                            data.count =
                                garmentFacets[facetKey].data.find(
                                    (newData) => newData.value === data.value
                                )?.count || 0
                        })

                        cloneLocalFilters[garmentType][facetKey] = {
                            ...cloneLocalFilters[garmentType][facetKey],
                            data: facetKeyData,
                        }
                    }
                })
                setLocalFilters(cloneLocalFilters)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentFacets])

    useEffect(() => {
        if (
            garmentData &&
            currentGarment &&
            garmentData.items.length > 0 &&
            garmentData.items.findIndex(
                (garment) => garment.garment_id === currentGarment.garment_id
            ) === -1
        ) {
            dispatch(
                HandleLookRequest({
                    [garmentData.items[0].garment_type.toLowerCase()]: garmentData.items[0],
                })
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentData])

    const handleTypeClick = (value: string) => {
        trackEvent('Type Clicked', { catalog_type: value }, 'Menu')
        // We remove the outerwear if we click on another garment Type in swipe only
        if (location.pathname === '/swipe' && value !== 'OUTERWEAR' && look.request.outerwear) {
            const cloneLookRequest = JSON.parse(JSON.stringify(look.request))
            cloneLookRequest.outerwear = null
            dispatch(HandleLookRequest(cloneLookRequest))
        }
        dispatch(FetchTypeGarmentAction(value))
    }

    useEffect(() => {
        if (company && company.garment_types && company.garment_types.length && garmentType) {
            if (company.garment_types.indexOf(garmentType) === -1) {
                dispatch(FetchTypeGarmentAction(company.garment_types[0]))
            }
        }
        // eslint-disable-next-line
    }, [company])

    const handleFavoritesClick = (e) => {
        e.preventDefault()
        trackEvent(
            'Favorite Clicked',
            { favorite_count: favorites?.outfits?.length + favorites?.items?.length },
            'Menu'
        )
        if (location.pathname !== '/favorites') {
            return customHistory.push('/favorites')
        }
    }

    const handleFavoritesList = (value: any) => {
        trackEvent(
            'Favorite type Clicked',
            {
                favorite_type: value == '1' ? 'outfit' : 'item',
                favorite_count:
                    value == '1' ? favorites?.outfits?.length : favorites?.items?.length,
            },
            'Menu'
        )
        dispatch(SwitchFavoritesContentAction(value === '1'))
    }

    const handleTitleClick = (e) => {
        e.preventDefault()
        trackEvent('Home Clicked', {}, 'Menu')
        if (location.pathname !== '/swipe') {
            customHistory.push('/swipe')
        }
    }

    const handleBackClick = () => {
        trackEvent('Return Clicked', { back_to: customHistory.getBack() }, 'Menu')
        return customHistory.goBack()
    }

    const handleFilterClick = (e, path = null) => {
        e.preventDefault()
        if (path === '/model') {
            trackEvent('Filter Clicked', { filter_type: 'model' }, 'Menu')
        } else {
            trackEvent('Filter Clicked', { catalog_type: garmentType, filter_type: 'item' }, 'Menu')
        }
        dispatch(ChangeShowFiltersAction(!showFilters))
    }

    const handleOnFilterUpdate = (value?: string | null, name?: string) => {
        setLastFacetModified(name || '')
    }

    /**
     * Handle the clic on the sub header categories
     * @param facetValue string: the facetValue we just clicked
     * @returns
     */
    const handleSubHeaderClick = (facetValue: string) => {
        const isAll = facetValue === 'ALL'
        // If element is already selected we ignore
        if (
            (onlyOneSubCategory &&
                activeFilters[company.garment_category_facets[garmentType]][0] === facetValue) ||
            (Object.keys(activeFilters).length === 0 && isAll)
        ) {
            return
        }

        trackEvent(
            'Sub Header Clicked',
            { catalog_type: garmentType, catalog_category: facetValue },
            'Menu'
        )

        // We reset anyway the filters if we click on a tab
        dispatch(resetAllFilterForType(garmentType))
        setLastFacetModified(company.garment_category_facets[garmentType])

        getGarmentsTrigger({
            ...(allGarments[garmentType]?.filterGarmentId
                ? { garment_id: allGarments[garmentType].filterGarmentId }
                : null),
            type: garmentType,
            page: 1,
            filter: isAll ? {} : { [company.garment_category_facets[garmentType]]: [facetValue] },
        })

        if (!isAll) {
            dispatch(
                addFilterForType({
                    type: garmentType,
                    facetKey: company.garment_category_facets[garmentType],
                    facetValue,
                })
            )
        }
    }

    const handleViewCatalogClick = (e) => {
        e.preventDefault()
        trackEvent('Catalog Clicked', {}, 'Menu')
        if (location.pathname !== '/catalog') {
            return customHistory.push('/catalog')
        }
    }

    const handleOnScrollLeftContent = async (e: React.UIEvent<HTMLDivElement>) => {
        // Only handle scrolling on catalog page
        if (location.pathname !== '/catalog') {
            return
        }

        const containerHeight = e.currentTarget.clientHeight
        const { scrollHeight } = e.currentTarget

        const { scrollTop } = e.currentTarget
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100

        const lastQueryResult = allGarments[garmentType]?.current

        if (percentageScroll < 70 || isLoading || !lastQueryResult) {
            return
        }

        // Prevent useless call
        if (
            lastQueryResult &&
            allGarments[garmentType].all.length <=
                (currentPage - 1) * lastQueryResult.num_items_per_page
        ) {
            return
        }

        // Prevent loading 1 page that doesn't exist
        if (
            lastQueryResult.current_page_number * lastQueryResult.num_items_per_page >=
            lastQueryResult.total_count
        ) {
            return
        }
        getGarmentsTrigger({
            ...(allGarments[garmentType].filterGarmentId
                ? { garment_id: allGarments[garmentType].filterGarmentId }
                : null),
            type: garmentType,
            page: currentPage + 1,
        })
    }

    const menuArray = useMemo(() => {
        const tmpArray = []
        company.garment_types.forEach((localGarmentType) => {
            tmpArray.push({
                label: t(`layout.${localGarmentType.toLowerCase()}`),
                value: localGarmentType,
            })
        })

        return tmpArray
    }, [company.garment_types, t])

    const MenuOption = menuArray
        ? menuArray
        : [
              { label: t('layout.top'), value: 'TOP' },
              { label: t('layout.bottom'), value: 'BOTTOM' },
              { label: t('layout.dress'), value: 'DRESS' },
              { label: t('layout.outerwear'), value: 'OUTERWEAR' },
              { label: t('layout.other'), value: 'OTHER' },
          ]

    const domain = getQueryValue('domain')
    const withGarmentFilters =
        company.garment_filters === true || getQueryValue('garment_filters') !== null
    const withModelFilters =
        company.model_filters === true || getQueryValue('model_filters') !== null

    const showTabs = useMemo(
        () =>
            location.pathname !== '/model' &&
            location.pathname !== '/favorites' &&
            location.pathname !== '/product',
        [location]
    )

    const showSubHeader = useMemo(
        () => company.garment_category_facets && company.garment_category_facets[garmentType],
        [company, garmentType]
    )

    return (
        <>
            <Modal
                destroyOnClose
                visible={modalVisible && lookRecommendations !== null}
                footer={null}
                centered={window.innerWidth < 768 ? false : true}
                width={window.innerWidth < 768 ? '100%' : '50%'}
                onCancel={() => {
                    dispatch(handleModalAction(false))
                }}
                afterClose={() => {
                    dispatch(FetchRecommendationsAction(null))
                }}
                className='modal'
            >
                <ModalAddedCart />
            </Modal>
            {window.innerWidth < 768 && (
                <Modal
                    destroyOnClose
                    closable={false}
                    visible={showFilters}
                    footer={null}
                    centered={false}
                    width={'100%'}
                    onCancel={() => dispatch(ChangeShowFiltersAction(false))}
                    className='modal'
                >
                    <div style={{ width: '100%' }}>
                        <FilterResponsive
                            allFilters={localFilters}
                            isMobile
                            onFilterUpdate={handleOnFilterUpdate}
                        />
                    </div>
                </Modal>
            )}

            <Layout
                className={`layout layout--container layout--page-${location.pathname.substring(
                    1
                )} ${!withGarmentFilters ? 'layout--only-topbottom' : ''}`}
            >
                <Header className='layout--header'>
                    {/* Main Title */}
                    <div
                        className={`layout--header-title ${
                            company.garment_category_facets ? 'layout--header-title-separator' : ''
                        }`}
                    >
                        {domain == 'christmas.com' ? (
                            <h1
                                className={`title title--h1 title--h1-christmas title--main ${
                                    company.garment_types.length > 2 && withGarmentFilters
                                        ? 'title--hide-overflow'
                                        : ''
                                }`}
                                onClick={handleTitleClick}
                            >
                                {'Christmas outfit creator'}
                            </h1>
                        ) : (
                            <h1
                                className={`title title--h1 title--main ${
                                    company.garment_types.length > 2 && withGarmentFilters
                                        ? 'title--hide-overflow'
                                        : ''
                                }`}
                                onClick={handleTitleClick}
                            >
                                {t('layout.see_the_look')}
                            </h1>
                        )}
                    </div>

                    {/* Main Content */}
                    <div className='layout--header-main-container'>
                        <div className='layout--header-top-container'>
                            <div className='layout--header-left'>
                                <div className='layout--header-left-content'>
                                    <div className='layout--header-left-content-menu'>
                                        {showTabs && (
                                            <>
                                                <Tabs
                                                    onTabClick={(key) => handleTypeClick(key)}
                                                    activeKey={garmentType}
                                                    className={`menu menu--container`}
                                                >
                                                    {MenuOption &&
                                                        MenuOption.map(
                                                            (item: {
                                                                label: string
                                                                value: string
                                                            }) =>
                                                                company &&
                                                                company?.garment_types?.indexOf(
                                                                    item.value
                                                                ) !== -1 && (
                                                                    <TabPane
                                                                        key={item.value}
                                                                        tab={item.label}
                                                                    />
                                                                )
                                                        )}
                                                </Tabs>
                                            </>
                                        )}
                                        {location.pathname === '/favorites' && (
                                            <Tabs
                                                activeKey={favorites.isOutfit ? '1' : '2'}
                                                onTabClick={handleFavoritesList}
                                                className={`menu menu--container`}
                                            >
                                                <TabPane
                                                    tab={`${t('favorite.saved_outfits')} (${
                                                        favorites?.outfits?.length
                                                    })`}
                                                    key='1'
                                                />
                                                <TabPane
                                                    tab={`${t('favorite.saved_items')} (${
                                                        favorites?.items?.length
                                                    })`}
                                                    key='2'
                                                />
                                            </Tabs>
                                        )}
                                        {withGarmentFilters &&
                                            (location.pathname === '/swipe' ||
                                                location.pathname === '/catalog') && (
                                                <div
                                                    className='button--filter'
                                                    onClick={(e) =>
                                                        handleFilterClick(e, '/catalog')
                                                    }
                                                >
                                                    <Badge
                                                        count={numberActiveFilters}
                                                        offset={[-5, 10]}
                                                        color='white'
                                                        size='small'
                                                    >
                                                        <Button
                                                            icon={getFilterIcon()}
                                                            type='link'
                                                            className='button layout--header-filter-button'
                                                        />
                                                    </Badge>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className='layout--header-right'>
                                {location.pathname !== '/swipe' && (
                                    <div
                                        onClick={handleBackClick}
                                        className='button--underlined button--desktop'
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        {t(`layout.back_to.${customHistory.getBack()}`)}
                                    </div>
                                )}
                                {location.pathname !== '/swipe' && (
                                    <div style={{ flex: 1 }} className='button--mobile'>
                                        <Button
                                            onClick={handleBackClick}
                                            icon={<LeftOutlined />}
                                            className='button--icon button--mobile'
                                        />
                                    </div>
                                )}
                                <div className='layout--header-button-container'>
                                    {company?.enable_swipe && (
                                        <Button
                                            onClick={handleViewCatalogClick}
                                            className='button--outlined button--space-r button--third'
                                        >
                                            {t('layout.model_view')}
                                        </Button>
                                    )}
                                    <Button
                                        icon={
                                            favorites.outfits.length || favorites.items.length
                                                ? getFavoriteIcon('filled')
                                                : getFavoriteIcon('outlined')
                                        }
                                        type='primary'
                                        ghost
                                        onClick={handleFavoritesClick}
                                        className='button--outlined button--third'
                                    >
                                        {t('layout.favorites')}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {showTabs && showSubHeader && (
                            <div className='menu--sub-container menu--container-overflow'>
                                {localFilters && localFilters[garmentType] && (
                                    <Tabs
                                        activeKey={
                                            onlyOneSubCategory
                                                ? activeFilters[
                                                      company.garment_category_facets[garmentType]
                                                  ][0]
                                                : activeFilters &&
                                                  Object.keys(activeFilters).length > 0
                                                ? ''
                                                : 'ALL'
                                        }
                                        onTabClick={handleSubHeaderClick}
                                    >
                                        <TabPane key={'ALL'} tab={t('layout.all')} />
                                        {localFilters[garmentType][
                                            company.garment_category_facets[garmentType]
                                        ].data.map((localData) => {
                                            return (
                                                <TabPane
                                                    key={localData.value}
                                                    tab={localData.label || localData.value}
                                                />
                                            )
                                        })}
                                    </Tabs>
                                )}
                            </div>
                        )}
                    </div>
                </Header>

                <div className={`layout--content${showSubHeader ? '--with-subheader' : ''}`}>
                    <Content
                        id='layoutScrollableContent'
                        className={`layout--left-content${showSubHeader ? '--with-subheader' : ''}`}
                        onScroll={handleOnScrollLeftContent}
                    >
                        <div
                            style={{
                                ...(location.pathname === '/model' && { height: '100%' }),
                            }}
                        >
                            <div
                                style={{
                                    ...size.getLayoutScrollableContentStyle('minHeight'),
                                    ...(location.pathname === '/model' && { height: '100%' }),
                                }}
                            >
                                {props?.children}
                                {isLoading && !error && location.pathname === '/catalog' && (
                                    <div>
                                        <Loader />
                                    </div>
                                )}
                                {error && location.pathname === '/catalog' && (
                                    <div>
                                        {t('error.error') + (error as API.ErrorQuery).data.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        {location.pathname !== '/swipe' && <PoweredBy mobile />}
                    </Content>
                    <Content
                        id='layoutRightContentId'
                        className={`layout--right-content${
                            showSubHeader ? '--with-subheader' : ''
                        }`}
                        style={
                            contentActualWidth &&
                            (location.pathname === '/catalog' ||
                                location.pathname === '/favorites' ||
                                location.pathname === '/product' ||
                                location.pathname === '/swipe' ||
                                (location.pathname === '/model' && !withModelFilters))
                                ? { width: contentActualWidth, flex: 'none' }
                                : null
                        }
                    >
                        <div
                            style={{
                                ...size.getLayoutRightContentStyle('minHeight'),
                                height: '100%',
                            }}
                        >
                            {showFilters && window.innerWidth > 768 ? (
                                <FilterResponsive
                                    allFilters={localFilters}
                                    onFilterUpdate={handleOnFilterUpdate}
                                />
                            ) : (
                                <>
                                    {!showFilters &&
                                        location.pathname === '/model' &&
                                        withModelFilters && <FiltersModel mobile={false} />}
                                    {!showFilters &&
                                        location.pathname !== '/swipe' &&
                                        (location.pathname !== '/model' || !withModelFilters) && (
                                            <LookContainer
                                                scrollToRef={cartRef}
                                                contentMaxWidth={
                                                    contentMaxWidth - 2 * contentMargin
                                                }
                                            />
                                        )}
                                    {((!showFilters && location.pathname !== '/model') ||
                                        !withModelFilters) && <CartPage ref={cartRef} swipeStyle />}
                                </>
                            )}
                        </div>
                    </Content>

                    <LookTracking />
                </div>
            </Layout>
        </>
    )
}

export default LayoutContainer
