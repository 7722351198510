// @ts-nocheck
import React, { useEffect } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider } from 'antd'

import { GetCompanyProfileCall } from './store/actions/profile'
import { GetAllModelsCall } from './store/actions/modelProduct'
import { handleCompleteLoaderAction, handleStartLoaderAction } from './store/actions/loader'

import HomePage from './pages'
import ProductPage from './pages/product'
import ModelPage from './pages/model'
import FavoritesPage from './pages/favorites'

import Layout from './containers/layout/layout'

import { getQueryGender, getQueryValue } from 'src/utils/query'
import { inIframe, sendIframeMessage } from 'src/utils/iframe'

import 'src/i18n/config'
import SwipePage from './pages/swipe'
import Loader from './components/Loader'

import history from './utils/history'
import { DEBUG_MODE } from './settings/global'
import { ChangeStorageBaseKey } from './store/actions/favorites'
import { GetLookCall } from './store/actions/look'
import Error from './components/Error'

const App: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const company = useSelector((state: State.Root) => state.profile?.company)
    const loader = useSelector((state: State.Root) => state.loader)
    const error = useSelector((state: State.Root) => state.error)
    const look = useSelector((state: State.Root) => state.look.current)

    const styleOverride = !DEBUG_MODE ? null : ``

    useEffect(() => {
        const fetchData = async () => {
            dispatch(handleStartLoaderAction())
            await dispatch(GetCompanyProfileCall())
        }
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        return history.listen((location) => {
            if (inIframe()) {
                const messageData = {
                    pathname: location.pathname,
                }
                // ---- Remettre les champs de store/index ----
                for (const key of ['look_id', 'type']) {
                    const value = getQueryValue(key)
                    if (value !== null) {
                        messageData[key] = value
                    }
                }
                sendIframeMessage('veesual_location', messageData)
            }
        })
        // eslint-disable-next-line
    }, [])

    // ---- Chargement des favoris en fonction de l'override Gender ----
    useEffect(() => {
        const asyncFetchModelAndLook = async () => {
            const promises = [
                dispatch(GetAllModelsCall()),
                dispatch(GetLookCall(getQueryValue('look_id'))),
            ]
            await Promise.all(promises)
            dispatch(handleCompleteLoaderAction())
        }

        if (company) {
            const genderValue = getQueryGender(company.genders)
            if (genderValue) {
                dispatch(ChangeStorageBaseKey(genderValue))
            }
            asyncFetchModelAndLook()
        }
        // eslint-disable-next-line
    }, [company])

    useEffect(() => {
        // ---- Primary color surchargee en url ----
        let primaryColor = getQueryValue('primary_color')
        if (typeof primaryColor === 'string' && primaryColor.match(/^0+$/)) {
            primaryColor = 'black'
        }

        // ---- Chargement du theme si present dans la config ----
        if (company && company.custom_view) {
            const { custom_colors } = company
            ConfigProvider.config({
                theme: {
                    primaryColor: primaryColor || custom_colors.primary_color,
                    infoColor: custom_colors.info_color,
                    successColor: custom_colors.success_color,
                    processingColor: custom_colors.processing_color,
                    errorColor: custom_colors.error_color,
                    warningColor: custom_colors.warning_color,
                },
            })
            const root = document.documentElement
            root?.style.setProperty(
                '--scrollbar-color',
                primaryColor || custom_colors.primary_color
            )
        }
        // ---- Sinon on force au moins la primary color dans le theme ----
        else if (primaryColor) {
            ConfigProvider.config({
                theme: {
                    primaryColor: primaryColor,
                },
            })
            const root = document.documentElement
            root?.style.setProperty('--scrollbar-color', primaryColor)
        }
    }, [company])

    const domain = getQueryValue('domain')
    const fontCss =
        domain == 'marinehenrion.com'
            ? './marinehenrion/font.css'
            : domain == 'christmas.com'
            ? './christmas/font.css'
            : domain == 'farfetch.com'
            ? './farfetch/font.css'
            : domain == 'facilenfil.fr'
            ? './facilenfil/font.css'
            : domain == 'lululemon.fr'
            ? './lululemon/font.css'
            : domain == 'jules.com'
            ? './jules/font.css'
            : null

    if ((!loader.isComplete || !company || !look) && !error.message) {
        return (
            <div className='App'>
                {fontCss && <link rel='stylesheet' href={fontCss} />}
                <Loader />
            </div>
        )
    }

    if (error.message) {
        return <Error />
    }

    return (
        <div className='App'>
            {(styleOverride || company.override_style) && (
                <style
                    dangerouslySetInnerHTML={{ __html: styleOverride || company.override_style }}
                ></style>
            )}

            {fontCss && <link rel='stylesheet' href={fontCss} />}
            <Router history={history}>
                <Switch>
                    <Layout>
                        <Route exact path='/'>
                            <Redirect to={'/swipe' + window.location.search} />
                        </Route>
                        <Route exact path='/catalog'>
                            <HomePage />
                        </Route>
                        <Route exact path='/product'>
                            <ProductPage />
                        </Route>
                        <Route exact path='/model'>
                            <ModelPage />
                        </Route>
                        <Route exact path='/favorites'>
                            <FavoritesPage />
                        </Route>
                        <Route exact path='/swipe'>
                            {company?.enable_swipe ? (
                                <SwipePage />
                            ) : (
                                <Redirect to={'/' + window.location.search} />
                            )}
                        </Route>
                    </Layout>
                </Switch>
            </Router>
        </div>
    )
}

export default App
