import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'

import Slider from 'react-slick'

import CarouselCard from 'src/components/card/cardCarousel'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { trackEvent } from 'src/utils/tracking'
import Loader from '../Loader'
import { calcMaxWidth } from 'src/utils/calc-max-width'
import useCustomSize from 'src/utils/size'
import { shouldHide } from 'src/utils/lazyLoadCarousel'

const CarouselModel: React.FunctionComponent = () => {
    const { t } = useCustomTranslation()
    const size = useCustomSize()
    const look = useSelector((state: State.Root) => state.look?.request)
    const data = useSelector((state: State.Root) => state.model?.all?.items)
    const modelInfinite = useSelector((state: State.Root) => state.profile?.company?.model_infinite)
    const lookRatio = useSelector((state: State.Root) => state.profile?.company?.look_image_ratio)
    const parentHeight = useSelector((state: State.Root) => state.profile?.parentHeight)

    const [contentMaxWidth, setContentMaxWidth] = useState<number>(null)

    useEffect(() => {
        let interval = null
        function handleResize() {
            const content = document.getElementById('layoutScrollableContent')
            const slideElem = document.getElementsByClassName('slick-slide')[0]
            if (content && slideElem) {
                clearInterval(interval)
                const tmpStyle = size.getLayoutScrollableContentStyle('height')
                const nbLines = tmpStyle.height ? 1 : 3
                const maxWidth = calcMaxWidth(
                    content.clientWidth,
                    0,
                    tmpStyle.height || content.clientHeight,
                    lookRatio,
                    // cardbody part height with number of lines + powered By height
                    110 + 16 * nbLines + 27,
                    0,
                    16
                )
                setContentMaxWidth(maxWidth)
            }
        }
        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [lookRatio, parentHeight])

    const initialSlide = !look
        ? 0
        : data.findIndex((model) => {
              return (
                  model.model_id === look.model.model_id ||
                  (model.identity_id &&
                      look.model.identity_id &&
                      model.identity_id === look.model.identity_id)
              )
          })
    const [currentSlide, setCurrentSlide] = useState(initialSlide)

    if (!look) {
        return <Loader />
    }
    if (!data) {
        return <div className='model--empty'></div>
    }
    if (!data.length) {
        return <div className='model--empty'>{t('model.no_models')}</div>
    }

    const toFlat = [data]
    if (modelInfinite !== false) {
        for (let i = data.length; i <= 3; i += data.length) {
            toFlat.push(data)
        }
    }
    const augmentedData = toFlat.flat()

    const CarouselNextArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        const handleClick = (e) => {
            trackEvent(
                'Swipe right',
                [augmentedData[currentSlide], { swipe_from: 'arrow' }],
                'Model Choice'
            )
            onClick(e)
        }

        return (
            <div className={`${className} carousel--arrow carousel--right`} onClick={handleClick}>
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps) => {
        const { className, onClick } = arrowProps

        const handleClick = (e) => {
            trackEvent(
                'Swipe left',
                [augmentedData[currentSlide], { swipe_from: 'arrow' }],
                'Model Choice'
            )
            onClick(e)
        }

        return (
            <div className={`${className} carousel--arrow carousel--left`} onClick={handleClick}>
                <LeftOutlined />
            </div>
        )
    }

    let slider = null

    const settings = {
        initialSlide: initialSlide,
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '0px',
        slidesToShow: Math.max(Math.min(augmentedData.length, 3), 1),
        swipeToSlide: true,
        focusOnSelect: true,
        touchThreshold: 15,
        slidesToScroll: 1,
        speed: 200,
        arrow: true,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        onInit: () => {
            // FIXME: la class slick-current n'est pas correctement geree quand on utilise initialSlide
            const interval = setInterval(() => {
                if (slider) {
                    slider.slickGoTo(initialSlide)
                    clearInterval(interval)
                }
            }, 100)
        },
        onSwipe: (direction) => {
            const adaptedDirection = direction == 'right' ? 'left' : 'right'
            trackEvent(
                `Swipe ${adaptedDirection}`,
                [augmentedData[currentSlide], { swipe_from: 'image' }],
                'Model Choice'
            )
        },
        beforeChange: (current, next) => {
            setCurrentSlide(next)
        },
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: Math.max(Math.min(augmentedData.length, 2), 1),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: Math.max(Math.min(augmentedData.length, 2), 1),
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: Math.max(Math.min(augmentedData.length, 3), 1),
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const imgRatio = 100 / (lookRatio || 0.66)

    return (
        <>
            <Slider
                {...settings}
                ref={(ref) => (slider = ref)}
                className='carousel carousel--container'
            >
                {augmentedData &&
                    augmentedData.length > 0 &&
                    augmentedData.map((item: Models.ModelProduct, itemKey: number) => (
                        <CarouselCard
                            key={itemKey}
                            id={itemKey}
                            current={currentSlide}
                            model={item}
                            ratio={imgRatio}
                            cardWidth={contentMaxWidth}
                            hide={shouldHide(
                                itemKey,
                                currentSlide,
                                augmentedData.length,
                                window.innerWidth < 768 ? 1 : 4,
                                true
                            )}
                        />
                    ))}
            </Slider>
        </>
    )
}

export default CarouselModel
