export const formattedPrice = (price: number | null, currency: string | null, float = 0) => {
    if (price === null) {
        price = 0
    }
    if (currency === null) {
        currency = 'EUR'
    }
    const formated = price.toFixed(float)
    if (currency == 'EUR') {
        return formated + ' €'
    } else if (currency == 'USD') {
        return '$' + formated
    } else if (currency == 'GBP') {
        return '£' + formated
    } else if (currency === null) {
        return formated
    }
    return formated + ' ' + currency
}
