import React, { useEffect } from 'react'

import Catalog from '../containers/catalog/Catalog'

import { trackPage } from 'src/utils/tracking'
import { useSelector } from 'react-redux'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'
import { useAppSelector } from 'src/store'
import { getAllGarments } from 'src/store/slices/databaseSlice'

const HomePage: React.FunctionComponent = () => {
    const look = useSelector((state: State.Root) => state.look?.request)
    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const [getGarmentsTrigger] = useCustomGetGarments()

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (garmentType && !allGarments[garmentType]) {
            getGarmentsTrigger({
                garment_id: look[garmentType.toLowerCase()]?.garment_id,
                type: garmentType,
                page: 1,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentType])

    return <Catalog />
}

export default HomePage
