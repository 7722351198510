import React, { useState } from 'react'
import useCustomTranslation from 'src/utils/translation'
import Card from 'antd/lib/card'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'
import Image from 'antd/lib/image'

import ImageSmooth from 'src/components/image/ImageSmooth'

import { resizeImage } from 'src/utils/image'
import { getQueryValue } from 'src/utils/query'
import { trackEvent } from 'src/utils/tracking'

import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { getFavoriteIcon, getSyncIcon } from 'src/utils/icon'
import { ReactFitty } from 'react-fitty'
import { useSelector } from 'react-redux'
import { isLookInFavorites } from 'src/utils/favorite'

interface CardModelProps {
    look: Models.Look
    lookLoading: boolean
    imageUrls: string[]
    index: number
    ratio: number
    favorites: Models.Look[]
    addOnFavorites(value: Models.Look): void
    removeFromFavorites(item: Models.Look): void
    handleBackClick?(index: number, e: React.MouseEvent): void
}

const CardModel: React.FunctionComponent<CardModelProps> = (props) => {
    const { t } = useCustomTranslation()
    const {
        look,
        lookLoading,
        imageUrls,
        index,
        favorites,
        addOnFavorites,
        removeFromFavorites,
        ratio,
        handleBackClick,
    } = props
    const company = useSelector((state: State.Root) => state.profile?.company)
    const inFavorites = isLookInFavorites(favorites, look)
    const [visible, setVisible] = useState(false)

    const domain = getQueryValue('domain')
    const withInfo = !domain || domain !== 'marinehenrion.com'

    const handleCardClick = () => {
        trackEvent('Outfit Clicked', [look, { outfit_index_selected: index }], 'Outfit')
    }

    const handleAddToFavorite = (e) => {
        e.stopPropagation()
        trackEvent('Outfit Saved to favorite', [look, { outfit_index_selected: index }], 'Outfit')
        addOnFavorites(look)
    }

    const handleRemoveFromFavorite = (e) => {
        e.stopPropagation()
        trackEvent(
            'Outfit Removed from favorite',
            [look, { outfit_index_selected: index }],
            'Outfit'
        )
        removeFromFavorites(look)
    }

    const handleZoomClick = (e) => {
        e.stopPropagation()
        trackEvent('Zoom Opened', [look, { outfit_index_selected: index }], 'Outfit')
        setVisible(true)
    }

    const handlePoweredClick = () => {
        trackEvent('Powered Clicked', [look, { outfit_index_selected: index }], 'Outfit')
    }

    const utms = `utm_source=client_experience&utm_medium=powered_by&utm_campaign=${company.internal}`

    return (
        <Card
            className='card-model card-model--container override_img_container'
            onClick={handleCardClick}
            cover={
                <div className='card-model--image-container'>
                    <div className='card-model--image'>
                        <ImageSmooth
                            className='card-model--background'
                            src={imageUrls ? resizeImage(imageUrls[index], { width: 800 }) : 'none'}
                            ratio={ratio}
                            lazyload={false}
                            loader={true}
                            loading={lookLoading}
                            cover={true}
                        />
                    </div>
                </div>
            }
        >
            {look && (
                <>
                    <div className='card-model--bottom'>
                        <Row className='card-model--bottom-left'>
                            {look.image_urls.length > 1 && handleBackClick ? (
                                <div
                                    className={`look--icon-with-label`}
                                    onClick={() => handleBackClick(index === 0 ? 1 : 0, null)}
                                >
                                    <Button type='link' icon={getSyncIcon()} />
                                </div>
                            ) : (
                                <span className='empty'></span>
                            )}
                        </Row>
                        <div className='card-model--bottom-center'>
                            <ReactFitty minSize={9} maxSize={14} wrapText={true}>
                                {look.model?.model_description ||
                                    t('model.description_short', {
                                        modelName: look.model.model_name,
                                        modelHeight: `${Math.floor(
                                            parseInt(look.model.model_height) / 100
                                        )}m${parseInt(look.model.model_height) % 100}`,
                                        modelSize: look.model.model_garment_size,
                                    })}
                            </ReactFitty>
                        </div>
                        <Button
                            icon={<SearchOutlined />}
                            type='link'
                            className='button card-model--bottom-right'
                            onClick={handleZoomClick}
                        />
                    </div>
                    <div className='card-model--top'>
                        <Tooltip
                            trigger={['hover']}
                            placement='left'
                            overlayClassName='card-model card-model--tooltip'
                            title={t('look.favorite_info')}
                        >
                            {!inFavorites ? (
                                <Button
                                    onClick={handleAddToFavorite}
                                    icon={getFavoriteIcon('outlined')}
                                    type='link'
                                    className='button'
                                />
                            ) : (
                                <Button
                                    icon={getFavoriteIcon('filled')}
                                    type='link'
                                    className='button'
                                    onClick={handleRemoveFromFavorite}
                                />
                            )}
                        </Tooltip>
                        {withInfo && (
                            <Tooltip
                                trigger={['click', 'hover']}
                                placement='left'
                                overlayClassName='card-model card-model--tooltip'
                                title={
                                    <div style={{ textAlign: 'left' }}>
                                        {look.model.model_description ||
                                            t('model.description', {
                                                modelName: look.model.model_name,
                                                modelHeight: `${Math.floor(
                                                    parseInt(look.model.model_height) / 100
                                                )}m${parseInt(look.model.model_height) % 100}`,
                                                modelSize: look.model.model_garment_size,
                                            })}
                                        <br />
                                        <br />
                                        {t('look.info')}
                                        <br />
                                        <br />
                                        {t('look.powered_by')}{' '}
                                        <a
                                            href={`https://www.veesual.ai?${utms}`}
                                            onClick={handlePoweredClick}
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            Veesual
                                        </a>
                                        {t('look.powered_by_after')}
                                    </div>
                                }
                            >
                                <Button
                                    icon={<InfoCircleOutlined />}
                                    type='link'
                                    className='button'
                                ></Button>
                            </Tooltip>
                        )}
                    </div>
                    {visible && (
                        <div style={{ display: 'none' }}>
                            <Image.PreviewGroup
                                preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
                            >
                                <Image src={resizeImage(imageUrls[index], { width: 800 })} />
                                {imageUrls &&
                                    imageUrls.map(
                                        (item: any, key: number) =>
                                            key !== index && (
                                                <Image
                                                    key={key}
                                                    src={resizeImage(item, { width: 800 })}
                                                />
                                            )
                                    )}
                            </Image.PreviewGroup>
                        </div>
                    )}
                </>
            )}
        </Card>
    )
}

export default CardModel
