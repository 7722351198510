import React from 'react'
import Collapse from 'antd/lib/collapse'
import Checkbox from 'antd/lib/checkbox'

import { DownOutlined } from '@ant-design/icons'
const { Panel } = Collapse

interface CollpaseCheckboxProps {
    placeholder: string
    options: any
    name: string
    value?: any[]
    onChange(e: any, name: string): void
    disable?: boolean
}

const CollpaseCheckbox: React.FunctionComponent<CollpaseCheckboxProps> = (props: any) => {
    const { placeholder, options, name, value, onChange, disable } = props

    return (
        <div className='collapse'>
            <Collapse
                expandIconPosition='right'
                className='collapse--container'
                expandIcon={() => <DownOutlined />}
            >
                <Panel header={placeholder} key='1' className='collapse--checkbox-container'>
                    {options.map((item: any, itemKey: number) => (
                        <Checkbox
                            checked={value?.includes(item.value)}
                            key={itemKey}
                            onChange={(e) => onChange(item.value, name, e)}
                            disabled={item.count === 0 || disable}
                        >
                            <div>
                                {`${item.label ? item.label : item.value} ${
                                    item.count !== null ? `(${item.count})` : ''
                                }`}
                            </div>
                        </Checkbox>
                    ))}
                </Panel>
            </Collapse>
        </div>
    )
}

export default CollpaseCheckbox
