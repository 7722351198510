import ReduxQuerySync from 'redux-query-sync'

import history from '../utils/history'

import rootReducer from './reducers'
import { apiGarment } from './api/api-garment'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiGarment.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

// Export the typed useSelector hook to use in react component
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

import { SetTypesFromQueryCall } from 'src/store/actions/garment'
import { GetLookFromQueryCall } from 'src/store/actions/look'

ReduxQuerySync({
    store,
    params: {
        // ---- A garder en sync avec App.tsx ----
        type: {
            selector: (state) => state.garment?.queryType,
            action: (value) => SetTypesFromQueryCall(value),
            stringToValue: (string) => string,
            valueToString: (value) => `${value}`,
            defaultValue: 'TOP',
        },
        look_id: {
            selector: (state) => state.look?.queryLookId,
            action: (value) => GetLookFromQueryCall(value),
            stringToValue: (string) => string,
            valueToString: (value) => `${value}`,
            defaultValue: undefined,
        },
    },
    initialTruth: 'location',
    replaceState: true,
    history,
})

export default store
