import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { trackPage } from 'src/utils/tracking'
import CartPage from './cart'
import LookContainer from 'src/containers/look/Look'
import Loader from 'src/components/Loader'
import CarouselGarment from 'src/components/carousel/carouselGarment'
import { HandleLookRequest } from 'src/store/actions/look'
import { calcMaxWidth } from 'src/utils/calc-max-width'
import useCustomSize from 'src/utils/size'
import PoweredBy from 'src/components/PoweredBy'
import { getAllGarments } from 'src/store/slices/databaseSlice'
import { useAppSelector } from 'src/store'
import useCustomTranslation from 'src/utils/translation'
import useCustomGetGarments from 'src/utils/custom-getGarments-hook'

const TOP = 'TOP'
const BOTTOM = 'BOTTOM'
const DRESS = 'DRESS'
const OUTERWEAR = 'OUTERWEAR'

const SwipePage: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    const size = useCustomSize()
    const { t } = useCustomTranslation()
    const garmentType = useSelector((state: State.Root) => state.garment?.type)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const allGarments = useAppSelector((state) => getAllGarments(state))
    const look = useSelector((state: State.Root) => state.look?.request)
    const lookRatio = useSelector((state: State.Root) => state.profile?.company?.look_image_ratio)
    const swipeTopRatio = useSelector(
        (state: State.Root) => state.profile?.company?.swipe_top_ratio
    )
    const swipeBottomRatio = useSelector(
        (state: State.Root) => state.profile?.company?.swipe_bottom_ratio
    )
    const swipeDressRatio = useSelector(
        (state: State.Root) => state.profile?.company?.swipe_dress_ratio
    )
    const swipeOuterwearRatio = useSelector(
        (state: State.Root) => state.profile?.company?.swipe_outerwear_ratio
    )
    const parentHeight = useSelector((state: State.Root) => state.profile?.parentHeight)

    const [getGarmentsTrigger, { isLoading, error }] = useCustomGetGarments()

    const cartRef = useRef(null)
    const [contentMaxWidth, setContentMaxWidth] = useState<number>(null)
    const [hasEnoughWidth, setHasEnoughWidth] = useState<boolean>(false)

    useEffect(() => {
        trackPage()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let interval = null
        function handleResize() {
            const content = document.getElementById('layoutSwipeContentId')
            const localContainerElem = document.getElementById('lookContainerId')
            if (content) {
                /**
                 * After we have the content we don't have directly the localContainer as we need to first set the maxWidth value. So we clear the interval the second time we enter here
                 */
                if (localContainerElem) {
                    clearInterval(interval)
                }
                const tmpStyle = size.getLayoutScrollableContentStyle('height')

                // ---- We calculate the maxWidth WITH TOGGLE to check if there is enough space ----
                let maxWidthWithoutToggle = calcMaxWidth(
                    content.clientWidth,
                    window.innerWidth / 4,
                    tmpStyle.height || content.clientHeight,
                    lookRatio,
                    // offset = 30 description + 32 toggles
                    30 + 32
                )
                const enoughWidthWithOffset =
                    localContainerElem &&
                    Math.max(maxWidthWithoutToggle / 3, 150) <
                        (localContainerElem.clientWidth - maxWidthWithoutToggle) / 2
                setHasEnoughWidth(enoughWidthWithOffset)

                // ---- If there is no Toggle under the look we remove the offset ----
                if (
                    enoughWidthWithOffset ||
                    company.enable_switch_row === false ||
                    (!company.enable_tuck &&
                        company.garment_types.findIndex((type) => type === 'OUTERWEAR') === -1)
                ) {
                    maxWidthWithoutToggle = calcMaxWidth(
                        content.clientWidth,
                        window.innerWidth / 4,
                        tmpStyle.height || content.clientHeight,
                        lookRatio,
                        // offset = 30 description
                        30
                    )
                }
                setContentMaxWidth(maxWidthWithoutToggle)
            }
        }
        interval = setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [lookRatio, parentHeight])

    useEffect(() => {
        if (!garmentType || !look || isLoading || error) {
            return
        }

        if (
            !allGarments[garmentType] ||
            (look[garmentType.toLowerCase()] &&
                !allGarments[garmentType].all.find(
                    (garment) => garment.garment_id === look[garmentType.toLowerCase()].garment_id
                ))
        ) {
            getGarmentsTrigger({
                garment_id: look[garmentType.toLowerCase()]?.garment_id,
                type: garmentType,
                page: 1,
            })
        }

        // We need to check if we have the data for BOTTOM or TOP according to the current garmentType
        // If not we fetch it
        if (garmentType === TOP && !allGarments[BOTTOM]) {
            getGarmentsTrigger({
                garment_id: look[BOTTOM.toLowerCase()]?.garment_id,
                type: BOTTOM,
                page: 1,
                filter: {},
            })
        }

        if (garmentType === BOTTOM && !allGarments[TOP]) {
            getGarmentsTrigger({
                garment_id: look[TOP.toLowerCase()]?.garment_id,
                type: TOP,
                page: 1,
                filter: {},
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [garmentType, look])

    useEffect(() => {
        if (look && allGarments) {
            switch (garmentType) {
                case TOP:
                case BOTTOM:
                    if (
                        (!look.top || !look.bottom) &&
                        allGarments[TOP]?.all.length &&
                        allGarments[BOTTOM]?.all.length
                    ) {
                        dispatch(
                            HandleLookRequest({
                                top: allGarments[TOP].all[0],
                                bottom: allGarments[BOTTOM].all[0],
                                outerwear: null,
                            })
                        )
                    }
                    break
                case DRESS:
                    if (!look.dress && allGarments[DRESS]?.all.length) {
                        dispatch(
                            HandleLookRequest({ dress: allGarments[DRESS].all[0], outerwear: null })
                        )
                    }
                    break
                default:
                    if (!look[garmentType.toLowerCase()] && allGarments[garmentType]?.all.length) {
                        dispatch(
                            HandleLookRequest({
                                [garmentType.toLowerCase()]: allGarments[garmentType].all[0],
                            })
                        )
                    }
                    break
            }
        }
        // eslint-disable-next-line
    }, [garmentType, allGarments])

    return (
        <>
            {error ? (
                <div>{t('error.error') + (error as API.ErrorQuery).data.message}</div>
            ) : !look ||
              !garmentType ||
              ([TOP, BOTTOM].indexOf(garmentType) !== -1 &&
                  (!allGarments.TOP || !allGarments.BOTTOM)) ||
              !allGarments[garmentType] ? (
                <Loader />
            ) : (
                <div
                    id='layoutSwipeContentId'
                    className={`layout--swipe-content${
                        company.garment_category_facets &&
                        company.garment_category_facets[garmentType]
                            ? '--with-subheader'
                            : ''
                    }`}
                >
                    {contentMaxWidth && (
                        <>
                            {[TOP, BOTTOM].indexOf(garmentType) !== -1 && (
                                <>
                                    <div
                                        className='swipe--carousel swipe--carousel-top'
                                        style={{
                                            top:
                                                (contentMaxWidth / lookRatio) *
                                                (swipeTopRatio?.top || 0.25),
                                        }}
                                    >
                                        <CarouselGarment
                                            type={TOP}
                                            cardHeight={
                                                (contentMaxWidth / lookRatio) *
                                                (swipeTopRatio?.height || 0.25)
                                            }
                                        />
                                    </div>
                                    <div
                                        className='swipe--carousel swipe--carousel-bottom'
                                        style={{
                                            top:
                                                (contentMaxWidth / lookRatio) *
                                                (swipeBottomRatio?.top || 0.5),
                                        }}
                                    >
                                        <CarouselGarment
                                            type={BOTTOM}
                                            cardHeight={
                                                (contentMaxWidth / lookRatio) *
                                                (swipeBottomRatio?.height || 0.3)
                                            }
                                        />
                                    </div>
                                </>
                            )}
                            {garmentType === DRESS && (
                                <div
                                    className='swipe--carousel swipe--carousel-top'
                                    style={{
                                        top:
                                            (contentMaxWidth / lookRatio) *
                                            (swipeDressRatio?.top || 0.25),
                                    }}
                                >
                                    <CarouselGarment
                                        type={DRESS}
                                        cardHeight={
                                            (contentMaxWidth / lookRatio) *
                                            (swipeDressRatio?.height || 0.5)
                                        }
                                    />
                                </div>
                            )}
                            {garmentType === OUTERWEAR && (
                                <div
                                    className='swipe--carousel swipe--carousel-top'
                                    style={{
                                        top:
                                            (contentMaxWidth / lookRatio) *
                                            (swipeOuterwearRatio?.top || 0.25),
                                    }}
                                >
                                    <CarouselGarment
                                        type={OUTERWEAR}
                                        cardHeight={
                                            (contentMaxWidth / lookRatio) *
                                            (swipeOuterwearRatio?.height || 0.25)
                                        }
                                    />
                                </div>
                            )}

                            {/* DEFAULT CASE FOR GARMENT TYPE CAROUSEL */}
                            {[TOP, BOTTOM, DRESS, OUTERWEAR].indexOf(garmentType) === -1 && (
                                <div
                                    className='swipe--carousel swipe--carousel-top'
                                    style={{
                                        top:
                                            (contentMaxWidth / lookRatio) *
                                            (swipeTopRatio?.top || 0.25),
                                    }}
                                >
                                    <CarouselGarment
                                        type={garmentType}
                                        cardHeight={
                                            (contentMaxWidth / lookRatio) *
                                            (swipeTopRatio?.height || 0.25)
                                        }
                                    />
                                </div>
                            )}
                            <LookContainer
                                swipeStyle
                                scrollToRef={cartRef}
                                contentMaxWidth={contentMaxWidth}
                                hasEnoughWidth={hasEnoughWidth}
                            />
                            <div className='layout--headroom' ref={cartRef}>
                                <CartPage swipeStyle />
                            </div>
                            <PoweredBy mobile />
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default SwipePage
