// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Button from 'antd/lib/button'

import CardCart from 'src/components/card/cardCart'

const { Title, Paragraph } = Typography

import { formattedPrice } from 'src/utils/price'
import {
    addGarmentSizesToCart,
    getGarmentOptionSizes,
    getGarmentSizeLabel,
} from 'src/utils/garment'
import { trackPage, trackEvent } from 'src/utils/tracking'
import CardCartSwipe from 'src/components/card/cardCartSwipe'
import { AddFavoritesItemsAction, RemoveFavoritesItemsAction } from 'src/store/actions/favorites'
import PoweredBy from 'src/components/PoweredBy'
import { useLocation } from 'react-router-dom'
import useShowModal from 'src/utils/showModal'
import CustomSpinner from 'src/components/CustomSpinner'

interface CartPageProps {
    swipeStyle?: boolean
}

const CartPage = React.forwardRef<HTMLDivElement, CartPageProps>((props, ref) => {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()
    const location = useLocation()
    const showModal = useShowModal()
    const look = useSelector((state: State.Root) => state.look?.request)
    const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
    const cartUrl = useSelector((state: State.Root) => state.profile?.company?.external_cart_url)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const favorites = useSelector((state: State.Root) => state.favorites).items
    const [sizes, setSizes] = useState({})
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const firstCardFocusFunc = useRef(null)
    const { swipeStyle } = props

    const garmentImgRatio = 100 / (company?.garment_image_ratio || 0.66)

    const garmentTypes = company.garment_types
        ? company.garment_types
        : ['TOP', 'BOTTOM', 'DRESS', 'OUTERWEAR']

    useEffect(() => {
        if (!swipeStyle) {
            trackPage()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const newSizes = {}
        for (const type of garmentTypes) {
            const typeLower = type.toLowerCase()
            if (sizes[type] && look[typeLower]) {
                newSizes[type] = sizes[type]
            }
        }
        setSizes(newSizes)
        setGoToCart(false)
        // eslint-disable-next-line
    }, [look])

    if (!look) {
        return (
            <div className='cart cart--container'>
                <div className='cart--empty'>{t('cart.no_products')}</div>
            </div>
        )
    }

    let nbSelected = 0
    let totalPrice = 0
    let currency = null
    for (const type of garmentTypes) {
        const typeLower = type.toLowerCase()
        if (sizes[type] && look[typeLower]) {
            nbSelected += 1
            totalPrice += look[typeLower].product_price
        }
        if (look[typeLower] && currency === null) {
            currency = look[typeLower].product_currency
        }
    }

    const handleSizeChange = (type, value) => {
        setSizes({ ...sizes, [type]: value })
        setGoToCart(false)
    }

    const handleClear = () => {
        trackEvent(
            'Delete all Clicked',
            [
                look,
                { outfit_nb_items_selected: nbSelected, outfit_total_price_selected: totalPrice },
            ],
            'Outfit Detail'
        )
        setSizes({})
        setGoToCart(false)
    }

    const addOnFavorites = (current: Models.Garment) => {
        dispatch(AddFavoritesItemsAction([...favorites, current] as any))
    }

    const removeOnFavorites = (current: Models.Garment) => {
        const dataList: any = [...favorites]
        const currentIndex = dataList.findIndex((item) => item.garment_id === current.garment_id)
        if (currentIndex >= 0) dataList.splice(currentIndex, 1)
        dispatch(RemoveFavoritesItemsAction(dataList))
    }

    const handleAddToCart = () => {
        const params = []
        const garmentIds = []
        for (const type of garmentTypes) {
            const typeLower = type.toLowerCase()
            if (sizes[type]) {
                const typeOptionSize = getGarmentOptionSizes(look[typeLower])
                trackEvent(
                    'Item Added to cart',
                    [
                        look[typeLower],
                        {
                            item_size_selected: sizes[type],
                            item_size_selected_label: getGarmentSizeLabel(
                                sizes[type],
                                typeOptionSize
                            ),
                        },
                    ],
                    'Outfit Detail'
                )
                params.push({ garment: look[typeLower], currentSize: sizes[type] })
                garmentIds.push(look[typeLower].garment_id)
            }
        }
        if (params.length) {
            trackEvent(
                'Outfit Added to cart',
                [
                    look,
                    {
                        outfit_nb_items_selected: nbSelected,
                        outfit_total_price_selected: totalPrice,
                    },
                ],
                'Outfit Detail'
            )
            setAddingToCart(true)
            addGarmentSizesToCart(params, (success) => {
                if (!success) {
                    trackEvent(
                        'Error Adding look to cart',
                        [
                            look,
                            {
                                outfit_nb_items_selected: nbSelected,
                                outfit_total_price_selected: totalPrice,
                            },
                        ],
                        'Outfit Detail'
                    )
                    alert(t(`error.cart`))
                } else {
                    setGoToCart(true)
                    showModal(garmentIds, look, 'Outfit Detail')
                }
                setAddingToCart(false)
            })
        } else if (firstCardFocusFunc?.current) {
            firstCardFocusFunc?.current()
        }
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        trackEvent('Go to cart Clicked', [look], 'Outfit Detail')
        window.open(cartUrl)
    }

    return (
        <div
            ref={ref}
            className='cart cart--container'
            id='cartContainerId'
            style={location.pathname !== '/swipe' ? { minHeight: 0 } : {}}
        >
            <div>
                <Row className='cart'>
                    {garmentTypes.map((type, i) => {
                        const garment = look[type.toLowerCase()]
                        if (garment) {
                            return swipeStyle ? (
                                <CardCartSwipe
                                    addOnFavorites={addOnFavorites}
                                    removeFromFavorites={removeOnFavorites}
                                    favorites={favorites}
                                    ratio={garmentImgRatio}
                                    key={garment.garment_id}
                                    garment={garment}
                                    value={sizes[type] || null}
                                    onChange={(value) => handleSizeChange(type, value)}
                                    cardFocusFunc={!i ? firstCardFocusFunc : null}
                                />
                            ) : (
                                <CardCart
                                    ratio={garmentImgRatio}
                                    key={garment.garment_id}
                                    garment={garment}
                                    value={sizes[type] || null}
                                    onChange={(value) => handleSizeChange(type, value)}
                                />
                            )
                        }
                    })}
                </Row>
                <Row className='cart cart--total' style={swipeStyle && { paddingLeft: 0 }}>
                    <div className='cart cart--text'>
                        <Paragraph
                            ellipsis={{
                                rows: 1,
                            }}
                            className='text text--small'
                            style={{ marginBottom: 0 }}
                        >{`${
                            nbSelected > 1
                                ? t('cart.item_selected')
                                : t('cart.item_selected_singular')
                        }: ${nbSelected}`}</Paragraph>
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='title title--h2'
                        >
                            {formattedPrice(totalPrice, currency, priceFloat)}
                        </Title>
                    </div>
                    <div className='cart cart--button'>
                        <Button
                            onClick={handleClear}
                            disabled={!nbSelected}
                            className='button--clear'
                        >
                            {t('cart.clear_all')}
                        </Button>
                        {goToCart && cartUrl ? (
                            <Button className='button card--dark' onClick={handleGoToCart}>
                                {t('cart.go_to_cart')}
                            </Button>
                        ) : (
                            <Button
                                className='button card--dark'
                                disabled={addingToCart}
                                onClick={handleAddToCart}
                            >
                                {addingToCart ? <CustomSpinner /> : t('cart.add_to_cart')}
                            </Button>
                        )}
                    </div>
                </Row>
            </div>
            <PoweredBy />
        </div>
    )
})

export default CartPage
