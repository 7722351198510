import React from 'react'

import Icon from '@ant-design/icons'
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import { ReactComponent as BookmarkOutlined } from '../static/icons/bookmark.svg'
import { ReactComponent as BookmarkFilled } from '../static/icons/bookmark-solid.svg'
import { ReactComponent as FilterSvg } from '../static/icons/sliders-solid.svg'
import { ReactComponent as SyncSvg } from '../static/icons/sync.svg'
import store from 'src/store'

export const getFavoriteIcon = (mode = 'outlined') => {
    const internal = store.getState().profile?.company?.internal

    switch (mode) {
        case 'outlined':
            if (['laredoute', 'laredoutees', 'laredouteuk'].indexOf(internal) !== -1) {
                return <Icon component={BookmarkOutlined} />
            }
            return <HeartOutlined />
        case 'filled':
            if (['laredoute', 'laredoutees', 'laredouteuk'].indexOf(internal) !== -1) {
                return <Icon component={BookmarkFilled} />
            }
            return <HeartFilled />
    }
}

export const getFilterIcon = () => {
    return <Icon component={FilterSvg} />
}

export const getSyncIcon = () => {
    return <Icon component={SyncSvg} />
}
