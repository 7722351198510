import {
    CHANGE_STORAGE_BASE_KEY,
    HANDLE_ADD_TO_FAVORITES_ITEMS,
    HANDLE_ADD_TO_FAVORITES_OUTFIT,
    HANDLE_REMOVE_TO_FAVORITES_ITEMS,
    HANDLE_REMOVE_TO_FAVORITES_OUTFIT,
    SWITCH_FAVORITES_CONTENT,
} from '../actions'

import { getQueryValue } from 'src/utils/query'

export const loadFromLocalStorage = (field) => {
    try {
        const serialized = localStorage.getItem(field)
        if (serialized !== null) {
            const unserialized = JSON.parse(serialized)
            if (Array.isArray(unserialized)) {
                return unserialized
            }
        }
    } catch (e) {
        // ---- Cannot use localStorage ----
    }
    return []
}

const saveIntoLocalStorage = (field, value) => {
    try {
        localStorage.setItem(field, JSON.stringify(value))
    } catch (e) {
        // ---- Cannot use localStorage ----
    }
    return value
}

const domain = getQueryValue('domain') || 'undefined'
const experienceId = getQueryValue('experience_id')
const storageBaseKey = domain + (experienceId ? '_' + experienceId : '')

const loadOldLocalStorageIfNeeded = (key) => {
    // ---- Exception pour laredoute ----
    if (
        ['laredoute.fr', 'laredoute.es', 'laredoute.co.uk'].indexOf(domain) !== -1 &&
        experienceId
    ) {
        const baseKey = `${key}.favorites`
        if (
            !loadFromLocalStorage(`${baseKey}.outfits`).length &&
            !loadFromLocalStorage(`${baseKey}.items`).length
        ) {
            const oldBaseKey = baseKey.replace(storageBaseKey, domain)
            saveIntoLocalStorage(
                `${baseKey}.outfits`,
                loadFromLocalStorage(`${oldBaseKey}.outfits`)
            )
            saveIntoLocalStorage(`${baseKey}.items`, loadFromLocalStorage(`${oldBaseKey}.items`))
        }
    }
}
loadOldLocalStorageIfNeeded(storageBaseKey)

export const initialState: State.Favorites = {
    outfits: loadFromLocalStorage(`${storageBaseKey}.favorites.outfits`),
    items: loadFromLocalStorage(`${storageBaseKey}.favorites.items`),
    isOutfit: false,
    storageBaseKey: storageBaseKey,
}

const GarmentReducers = (
    state: State.Favorites = initialState,
    action: Types.Action
): State.Favorites => {
    switch (action.type) {
        case HANDLE_ADD_TO_FAVORITES_ITEMS:
            return {
                ...state,
                items: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.items`,
                    action.payload
                ),
                isOutfit:
                    window.location.pathname === '/favorites' && state.outfits.length
                        ? state.isOutfit
                        : false,
            }
        case HANDLE_REMOVE_TO_FAVORITES_ITEMS:
            return {
                ...state,
                items: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.items`,
                    action.payload
                ),
                isOutfit: window.location.pathname === '/favorites' ? state.isOutfit : false,
            }

        case HANDLE_ADD_TO_FAVORITES_OUTFIT:
            return {
                ...state,
                outfits: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.outfits`,
                    action.payload
                ),
                isOutfit:
                    window.location.pathname === '/favorites' && state.items.length
                        ? state.isOutfit
                        : true,
            }
        case HANDLE_REMOVE_TO_FAVORITES_OUTFIT:
            return {
                ...state,
                outfits: saveIntoLocalStorage(
                    `${state.storageBaseKey}.favorites.outfits`,
                    action.payload
                ),
                isOutfit: window.location.pathname === '/favorites' ? state.isOutfit : true,
            }

        case SWITCH_FAVORITES_CONTENT:
            return {
                ...state,
                isOutfit: action.payload,
            }

        case CHANGE_STORAGE_BASE_KEY:
            loadOldLocalStorageIfNeeded(`${storageBaseKey}.${action.payload}`)
            return {
                ...initialState,
                outfits: loadFromLocalStorage(
                    `${storageBaseKey}.${action.payload}.favorites.outfits`
                ),
                items: loadFromLocalStorage(`${storageBaseKey}.${action.payload}.favorites.items`),
                storageBaseKey: `${storageBaseKey}.${action.payload}`,
            }

        default:
            return state
    }
}

export default GarmentReducers
