import React from 'react'
import { useDispatch } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import Card from 'antd/lib/card'
import Typography from 'antd/lib/typography'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'

import { HandleLookRequest } from 'src/store/actions/look'
import { resizeImage } from 'src/utils/image'
import { trackEvent } from 'src/utils/tracking'
import ImageSmooth from '../image/ImageSmooth'
import useCustomHistory from 'src/utils/custom-history-hook'

const { Title, Paragraph } = Typography

interface CardCarouselProps {
    id: number
    current: number
    model: Models.ModelProduct
    ratio: number
    cardWidth?: number
    hide: boolean
}

let checkForDragCardCarousel

const CardCarousel: React.FunctionComponent<CardCarouselProps> = (props) => {
    const dispatch = useDispatch()
    const customHistory = useCustomHistory()
    const { t } = useCustomTranslation()
    const { id, current, model, ratio, cardWidth, hide } = props

    const handleSelectClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        trackEvent('Model Selected', model, 'Model Choice')
        dispatch(HandleLookRequest({ model: model }))

        return customHistory.goBack()
    }

    const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }

        trackEvent(
            'Model card Clicked',
            [model, { model_card_current: id == current }],
            'Model Choice'
        )
        if (id === current) {
            handleSelectClick(e)
        }
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    if (hide) {
        return null
    }

    return (
        <Card
            className='card-carousel card-carousel--container override_card_container'
            hoverable
            onMouseDown={mouseDownCoords}
            onMouseUp={(e) => handleCardClick(e)}
            cover={
                <div className='card card--image-container'>
                    <div className={'card--image'}>
                        <ImageSmooth
                            overflow={false}
                            ratio={ratio}
                            src={resizeImage(model.image_url, { width: 800 })}
                            loader={true}
                            cover={true}
                        />
                    </div>
                </div>
            }
            style={cardWidth && { width: cardWidth }}
        >
            <Title
                ellipsis={{
                    rows: 1,
                }}
                className='card-carousel--title'
            >
                {model.model_name}
            </Title>
            <Paragraph
                ellipsis={{
                    rows: 3,
                }}
                className='card-carousel--text'
            >
                {model.model_description ||
                    t('model.description', {
                        modelName: model.model_name,
                        modelHeight: `${Math.floor(parseInt(model.model_height) / 100)}m${
                            parseInt(model.model_height) % 100
                        }`,
                        modelSize: model.model_garment_size,
                    })}
            </Paragraph>
            <Row align='middle' justify='center'>
                <Button
                    className='card-carousel--button'
                    type='primary'
                    disabled={id !== current}
                    onClick={handleSelectClick}
                >
                    {t('model.select')}
                </Button>
            </Row>
        </Card>
    )
}

export default CardCarousel
