import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'

import FilterSelect from '../select/filter'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart, getGarmentSizeLabel } from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking'

import ImageSmooth from '../image/ImageSmooth'
import CustomSpinner from '../CustomSpinner'

const { Title, Paragraph } = Typography

interface CardCartProps {
    garment: Models.Garment
    value: any
    onChange(e: any, name: string): void
    ratio: number
}

const CardCart: React.FunctionComponent<CardCartProps> = (props) => {
    const { t } = useCustomTranslation()
    const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
    const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)
    const [addingToCart, setAddingToCart] = useState(false)
    const sizeSelectRef = useRef(null)
    const { garment, value, ratio } = props

    const optionSize = getGarmentOptionSizes(garment)

    const imageUrl =
        useClipping && garment.image_clipping_url ? garment.image_clipping_url : garment.image_url

    const handleChange = (newValue, name) => {
        if (newValue) {
            trackEvent(
                'Size Selected',
                [
                    garment,
                    {
                        item_size_selected: newValue,
                        item_size_selected_label: getGarmentSizeLabel(newValue, optionSize),
                    },
                ],
                'Outfit Detail'
            )
        }
        props.onChange(newValue, name)
    }

    const handleAddToCart = () => {
        if (!value) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                garment,
                {
                    item_size_selected: value,
                    item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                },
            ],
            'Outfit Detail'
        )
        setAddingToCart(true)
        addGarmentSizeToCart(garment, value, (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        garment,
                        {
                            item_size_selected: value,
                            item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                        },
                    ],
                    'Outfit Detail'
                )
                alert(t(`error.cart`))
            }
            setAddingToCart(false)
        })
    }

    return (
        <Row className='card-cart card-cart--container override_card_container'>
            <Col xs={{ span: 24 }} xl={{ span: 4 }} className='card-cart--col'>
                <div className='card-cart--image'>
                    <ImageSmooth
                        ratio={ratio}
                        src={resizeImage(imageUrl, { width: 800 })}
                        lazyload={false}
                    />
                </div>
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 14 }} className='card-cart--content'>
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='title title--h2'
                >
                    {parse(garment.product_name)}
                </Title>
                <Paragraph
                    ellipsis={{
                        rows: 2,
                    }}
                    className='text text--small'
                >
                    {parse(garment.product_description)}
                </Paragraph>
                {garment.product_price_original &&
                    garment.product_price_original > garment.product_price && (
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='title card--price--promotion'
                        >
                            <span className='card--price--original'>
                                {formattedPrice(
                                    garment.product_price_original,
                                    garment.product_currency,
                                    priceFloat
                                )}
                            </span>
                            <span className='card--price--percent'>
                                -
                                {Math.round(
                                    ((garment.product_price_original - garment.product_price) *
                                        100) /
                                        garment.product_price_original
                                )}
                                %
                            </span>
                        </Title>
                    )}
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='title title--h3'
                >
                    {formattedPrice(garment.product_price, garment.product_currency, priceFloat)}
                </Title>
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 6 }}>
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='title title--h3 title--bottom'
                >{`${t('product.size')} : `}</Title>
                <FilterSelect
                    ref={sizeSelectRef}
                    name='size'
                    onChange={handleChange}
                    value={value}
                    options={optionSize}
                    translation={false}
                    placeholder={t('product.please_choose')}
                />
                <Button
                    className='button card--dark card-cart--add'
                    disabled={addingToCart}
                    onClick={handleAddToCart}
                >
                    {addingToCart ? <CustomSpinner /> : t('product.add_to_cart')}
                </Button>
            </Col>
        </Row>
    )
}

export default CardCart
