import Col from 'antd/lib/col'
import Switch from 'antd/lib/switch'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from 'src/store'
import { HandleLookRequest } from 'src/store/actions/look'
import { trackEvent } from 'src/utils/tracking'
import useCustomTranslation from 'src/utils/translation'

interface LookSwitchesProps {
    vertical?: boolean
}

/**
 * Component that stores the switches to change the look (tuck/untuck and Outerwear ON/OFF)
 * @param props {vertical?: boolean} to handle the orientation of the switches
 */
const LookSwitches: React.FunctionComponent<LookSwitchesProps> = (props) => {
    const { t } = useCustomTranslation()
    const dispatch = useDispatch()

    const look = useSelector((state: State.Root) => state.look?.request)
    const lookLoading = useSelector((state: State.Root) => state.look?.loading)
    const outerwear = useSelector((state: State.Root) => state.look?.outerwear)
    const company = useSelector((state: State.Root) => state.profile?.company)
    const garmentType = useAppSelector((state) => state.garment?.type)
    const [outerwearOn, setOuterwearOn] = useState(look && !!look.outerwear)

    const { vertical } = props

    useEffect(() => {
        if (look) {
            setOuterwearOn(!!look.outerwear)
        }
        // eslint-disable-next-line
    }, [look])

    const handleChangeTuck = (e) => {
        trackEvent('Tuck in Changed', [look, { tuck_value: e ? 'tuck' : 'untuck' }], 'Outfit')
        dispatch(HandleLookRequest({ mode: e ? 'tuck' : 'untuck' }))
    }

    const handleChangeCoat = (e) => {
        trackEvent('Coat on Changed', [look, { coat_value: e ? 'on' : 'off' }], 'Outfit')
        setOuterwearOn(e)

        if (!e) {
            dispatch(HandleLookRequest({ outerwear_garment_id: null, outerwear: null }))
        } else {
            dispatch(
                HandleLookRequest(
                    outerwear
                        ? { ['outerwear']: outerwear }
                        : { outerwear_garment_id: '__RANDOM__' }
                )
            )
        }
    }
    return (
        <>
            {company.enable_tuck && (
                <Col className={vertical ? 'look--switch-vertical' : 'look--switch'}>
                    <span>{t('look.tuck_in')}</span>
                    <Switch
                        size='small'
                        checked={look.mode === 'tuck'}
                        onChange={handleChangeTuck}
                        disabled={
                            !look ||
                            !company ||
                            !!look.dress ||
                            look.mode_switch === false ||
                            lookLoading > 0
                        }
                    />
                </Col>
            )}
            {company.garment_types.indexOf('OUTERWEAR') !== -1 && (
                <Col className={vertical ? 'look--switch-vertical' : 'look--switch'}>
                    <span>{t('look.coat_in')}</span>
                    <Switch
                        size='small'
                        checked={outerwearOn}
                        onChange={handleChangeCoat}
                        disabled={
                            !look ||
                            !company ||
                            (garmentType === 'OUTERWEAR' && location.pathname === '/swipe') ||
                            lookLoading > 0
                        }
                    />
                </Col>
            )}
        </>
    )
}
export default LookSwitches
