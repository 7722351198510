import React, { useEffect, useRef, useState } from 'react'
import useCustomTranslation from 'src/utils/translation'
import parse from 'html-react-parser'

import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'

import FilterSelect from '../select/filter'
import { resizeImage } from 'src/utils/image'
import { formattedPrice } from 'src/utils/price'
import { getGarmentOptionSizes, addGarmentSizeToCart, getGarmentSizeLabel } from 'src/utils/garment'
import { trackEvent } from 'src/utils/tracking'

import { useDispatch, useSelector } from 'react-redux'
import ImageSmooth from '../image/ImageSmooth'
import { getFavoriteIcon } from 'src/utils/icon'
import useCustomHistory from 'src/utils/custom-history-hook'
import { isGarmentInFavorites } from 'src/utils/favorite'
import useShowModal from 'src/utils/showModal'
import CustomSpinner from '../CustomSpinner'
import { HandleLookRequest } from 'src/store/actions/look'
import { FetchTypeGarmentAction } from 'src/store/actions/garment'

const { Title, Paragraph } = Typography

interface CardCartSwipeProps {
    garment: Models.Garment
    value: any
    onChange(e: any, name: string): void
    ratio: number
    addOnFavorites(value: Models.Garment): void
    removeFromFavorites(item: Models.Garment): void
    favorites: Models.Garment[]
    cardFocusFunc?: any
}

const CardCartSwipe: React.FunctionComponent<CardCartSwipeProps> = (props) => {
    const { t } = useCustomTranslation()
    const customHistory = useCustomHistory()
    const showModal = useShowModal()
    const dispatch = useDispatch()
    const priceFloat = useSelector((state: State.Root) => state.profile?.company?.price_float)
    const descriptionCatalog = useSelector(
        (state: State.Root) => state.profile?.company?.description_catalog
    )
    const cartUrl = useSelector((state: State.Root) => state.profile?.company?.external_cart_url)
    const useClipping = useSelector((state: State.Root) => state.profile?.company?.use_clipping)
    const [addingToCart, setAddingToCart] = useState(false)
    const [goToCart, setGoToCart] = useState(false)
    const sizeSelectRef = useRef(null)
    const { garment, value, ratio, addOnFavorites, removeFromFavorites, favorites, cardFocusFunc } =
        props

    const inFavorites = isGarmentInFavorites(favorites, garment)

    const optionSize = getGarmentOptionSizes(garment)

    const imageUrl =
        useClipping && garment.image_clipping_url ? garment.image_clipping_url : garment.image_url

    useEffect(() => {
        if (cardFocusFunc) {
            cardFocusFunc.current = () => {
                if (!value) {
                    return sizeSelectRef.current?.focus()
                }
            }
        }
        setGoToCart(false)
        // eslint-disable-next-line
    }, [value])

    const handleChange = (newValue, name) => {
        if (newValue) {
            trackEvent(
                'Size Selected',
                [
                    garment,
                    {
                        item_size_selected: newValue,
                        item_size_selected_label: getGarmentSizeLabel(newValue, optionSize),
                    },
                ],
                'Outfit Detail'
            )
        }
        props.onChange(newValue, name)
    }

    const handleAddToFavorite = (e) => {
        e.stopPropagation()
        trackEvent('Item Saved to favorite', garment, 'Outfit Detail')
        addOnFavorites(garment)
    }

    const handleRemoveFromFavorite = (e) => {
        e.stopPropagation()
        trackEvent('Item Removed from favorite', garment, 'Outfit Detail')
        removeFromFavorites(garment)
    }

    const handleAddToCart = () => {
        if (!value) {
            return sizeSelectRef.current?.focus()
        }
        trackEvent(
            'Item Added to cart',
            [
                garment,
                {
                    item_size_selected: value,
                    item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                },
            ],
            'Outfit Detail'
        )
        setAddingToCart(true)
        addGarmentSizeToCart(garment, value, (success) => {
            if (!success) {
                trackEvent(
                    'Error Adding item to cart',
                    [
                        garment,
                        {
                            item_size_selected: value,
                            item_size_selected_label: getGarmentSizeLabel(value, optionSize),
                        },
                    ],
                    'Outfit Detail'
                )
                alert(t(`error.cart`))
            } else {
                setGoToCart(true)
            }
            setAddingToCart(false)
        })
    }

    const handleGoToCart = (e) => {
        e.stopPropagation()
        trackEvent('Go to cart Clicked', [garment], 'Outfit Detail')
        window.open(cartUrl)
    }

    const handleMoreLook = (e) => {
        e.stopPropagation()
        showModal(garment.garment_id, garment, 'Outfit Detail')
    }

    const handleDetailClick = () => {
        trackEvent('Item Detailed', garment, 'Outfit Detail')
        dispatch(FetchTypeGarmentAction(garment.garment_type))
        dispatch(HandleLookRequest({ [garment.garment_type.toLowerCase()]: garment }))
        customHistory.push('/product')
    }

    return (
        <Row className='card-cart card-cart--container card-cart--container-swipe override_card_container'>
            <Col xs={{ span: 24 }} xl={{ span: 8 }} className='card-cart--col'>
                <div className='card-cart--image'>
                    <ImageSmooth
                        src={resizeImage(imageUrl, { width: 800 })}
                        ratio={ratio}
                        lazyload={false}
                    />
                </div>
                <div onClick={handleDetailClick} className='button--underlined'>
                    {t(`product.see_product`)}
                </div>
                {!inFavorites ? (
                    <Button
                        onClick={handleAddToFavorite}
                        icon={getFavoriteIcon('outlined')}
                        type='link'
                        className='button card--favorite'
                    ></Button>
                ) : (
                    <Button
                        icon={getFavoriteIcon('filled')}
                        type='link'
                        className='button card--favorite'
                        onClick={handleRemoveFromFavorite}
                    ></Button>
                )}
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 16 }} className='card-cart--content'>
                {garment.product_brand && (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                        }}
                        className='text text--small'
                    >
                        {parse(garment.product_brand)}
                    </Paragraph>
                )}
                <Title
                    ellipsis={{
                        rows: descriptionCatalog === false ? 2 : 1,
                    }}
                    className='title title--h3 card-cart--title-desktop'
                >
                    {parse(garment.product_name)}
                </Title>
                <Title
                    ellipsis={{
                        rows: 2,
                    }}
                    className='title title--h3 card-cart--title-mobile'
                >
                    {parse(garment.product_name)}
                </Title>
                {descriptionCatalog !== false && (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                        }}
                        className='text text--small'
                    >
                        {parse(garment.product_description)}
                    </Paragraph>
                )}
                {garment.product_price_original &&
                    garment.product_price_original > garment.product_price && (
                        <Title
                            ellipsis={{
                                rows: 1,
                            }}
                            className='title card--price--promotion'
                        >
                            <span className='card--price--original'>
                                {formattedPrice(
                                    garment.product_price_original,
                                    garment.product_currency,
                                    priceFloat
                                )}
                            </span>
                            <span className='card--price--percent'>
                                -
                                {Math.round(
                                    ((garment.product_price_original - garment.product_price) *
                                        100) /
                                        garment.product_price_original
                                )}
                                %
                            </span>
                        </Title>
                    )}
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='title title--h3'
                >
                    {formattedPrice(garment.product_price, garment.product_currency, priceFloat)}
                </Title>
                <div className='card-cart--flex'>
                    <Title
                        ellipsis={{
                            rows: 1,
                        }}
                        className='title title--h3 title--bottom card-cart--size'
                    >{`${t('product.size')} : `}</Title>
                    <FilterSelect
                        ref={sizeSelectRef}
                        name='size'
                        onChange={handleChange}
                        value={value}
                        options={optionSize}
                        translation={false}
                        placeholder={t('product.please_choose')}
                        customStyle={{ flex: 3 }}
                    />
                </div>
                {goToCart && cartUrl ? (
                    <Button className='button card--dark card-cart--add' onClick={handleGoToCart}>
                        {t('product.go_to_cart')}
                    </Button>
                ) : (
                    <Button
                        className='button card--dark card-cart--add'
                        disabled={addingToCart}
                        onClick={handleAddToCart}
                    >
                        {addingToCart ? <CustomSpinner /> : t('product.add_to_cart')}
                    </Button>
                )}
                {goToCart && (
                    <Button className='button--outlined card-cart--add' onClick={handleMoreLook}>
                        {t('cart.more_look')}
                    </Button>
                )}
            </Col>
        </Row>
    )
}

export default CardCartSwipe
